const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';

export function setSelectedClient(data: Object) {
    return {
        type: SET_SELECTED_CLIENT,
        data
    };
}

const initialState = {
    selectedClient: null
};

export default function projectState(state: Object = initialState, action: Object) {
    switch (action.type) {
        case SET_SELECTED_CLIENT:
            return {
                ...state,
                selectedClient: action.data
            };
        default:
            return state;
    }
}

const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export function setNotifications(data: Object) {
    return {
        type: SET_NOTIFICATIONS,
        data
    };
}

const initialState = {
    notifications: {}
};

export default function projectState(state: Object = initialState, action: Object) {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.data
            };
        default:
            return state;
    }
}

import * as React from 'react';
import type { ModalSize } from '../../types';

const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';
const CLEAR_MODAL_CONTENT = 'CLEAR_MODAL_CONTENT';

type Props = {
    title?: string,
    modalContent: string | React.Node,
    onToggle?: Function,
    className?: string,
    size?: ModalSize,
}

export function setModalContent(data: Object, modalType: String) {
    return {
        type: SET_MODAL_CONTENT,
        modalType,
        data
    };
}

export function clearModalContent(modalType: String) {
    return {
        type: CLEAR_MODAL_CONTENT,
        modalType
    };
}

const initialState = {
    main: {
        title: null,
        modalType: '',
        modalContent: null,
        className: '',
        size: 'md',
        onToggle: null
    },
    confirmation: {
        title: null,
        modalType: '',
        modalContent: null,
        className: '',
        size: 'md',
        onToggle: null
    },
    message: {
        title: null,
        modalType: '',
        modalContent: null,
        className: '',
        size: 'md',
        onToggle: null
    }
};

export default function modalEvent(state: Object = initialState, action): any {
    switch (action.type) {
        case SET_MODAL_CONTENT:
            return {
                ...state,
                [action.modalType]: {
                    ...action.data
                }
            };
        case CLEAR_MODAL_CONTENT:
            return {
                ...state,
                [action.modalType]: initialState[action.modalType]
            };
        default:
            return state;
    }
}

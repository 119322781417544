import moment from 'moment';
import { nullChecker } from '../../utils/helpers';

const SET_PROJECT_LIST = 'SET_PROJECT_LIST';
const SET_SELECTED_TASK = 'SET_SELECTED_TASK';
const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
const SET_CREATE_PROJECT_INPUT = 'SET_CREATE_PROJECT_INPUT';

const ADD_TASK = 'ADD_TASK';
const UPDATE_TASK = 'UPDATE_TASK';
const ADD_TASK_FROM_TABLE = 'ADD_TASK_FROM_TABLE';
const UPDATE_TASK_FROM_TABLE = 'UPDATE_TASK_FROM_TABLE';

const FILTER_TASKS = 'FILTER_TASKS';
const FILTER_PROGRESS_TASKS = 'FILTER_PROGRESS_TASKS';

const OFFSET_FILTERED_TASKS_COUNT = 'OFFSET_FILTERED_TASKS_COUNT';
const OFFSET_FILTERED_TASKS_TOTALS_PER_EPIC_COUNT = 'OFFSET_FILTERED_TASKS_TOTALS_PER_EPIC_COUNT';

const RESET_PROJECT_STATE = 'RESET_PROJECT_STATE';

export function setProjectList(projects: Array) {
    return {
        type: SET_PROJECT_LIST,
        projects
    };
}

export function setSelectedTask(taskId: Integer) {
    return {
        type: SET_SELECTED_TASK,
        taskId
    };
}

export function setSelectedProject(projectDetails: Object) {
    return {
        type: SET_SELECTED_PROJECT,
        projectDetails
    };
}

export function filterTasks(
    isInit: Boolean,
    status: Array,
    statusList: Array,
    modifiedEstimate: Boolean,
    allLabels: Array,
    keyFields: Array,
    estimatedOption: Integer,
    coveredDate: String,
    numOfDays: Integer
) {
    return {
        type: FILTER_TASKS,
        isInit,
        status,
        statusList,
        modifiedEstimate,
        allLabels,
        keyFields,
        estimatedOption,
        coveredDate,
        numOfDays
    };
}

export function filterProgressTasks(
    isInit: Boolean,
    progressStatus: Array,
    statusList: Array,
    progressedTask: Boolean,
    allLabels: Array,
    keyFields: Array,
    coveredDate: String,
    numOfDays: Integer
) {
    return {
        type: FILTER_PROGRESS_TASKS,
        isInit,
        progressStatus,
        statusList,
        progressedTask,
        allLabels,
        keyFields,
        coveredDate,
        numOfDays
    };
}

export function addTask(payload: Object) {
    return {
        type: ADD_TASK,
        payload
    };
}

export function updateTask(payload: Object) {
    return {
        type: UPDATE_TASK,
        payload
    };
}

export function addTaskFromTable(payload: Object) {
    return {
        type: ADD_TASK_FROM_TABLE,
        payload
    };
}

export function updateTaskFromTable(payload: Object) {
    return {
        type: UPDATE_TASK_FROM_TABLE,
        payload
    };
}

export function setCreateProjectInput(data: Object) {
    return {
        type: SET_CREATE_PROJECT_INPUT,
        data
    };
}

// for draggable table
export function offsetFilteredTasksCount(tableType: String, epicLength: Array, totalTasksDoneLength: Array) {
    return {
        type: OFFSET_FILTERED_TASKS_COUNT,
        tableType,
        epicLength,
        totalTasksDoneLength
    };
}

export function offsetFilteredTasksTotalsPerEpic(tableType: String, epicTotals: Array) {
    return {
        type: OFFSET_FILTERED_TASKS_TOTALS_PER_EPIC_COUNT,
        tableType,
        epicTotals
    };
}

export function resetProjectState() {
    return {
        type: RESET_PROJECT_STATE
    };
}

const initialState = {
    projectList: [],
    selectedTask: {},
    projectDetails: {},
    createProjectInput: {},
    epics: {
        filteredTasks: {
            counts: {},
            totalsPerEpic: [],
            allEpicsTotal: {}
        }
    },
    progress: {
        filteredTasks: {
            counts: {},
            totalsPerEpic: [],
            allEpicsTotal: {}
        }
    }
};

export default function projectState(state: Object = initialState, action: Object) {
    switch (action.type) {
        case SET_PROJECT_LIST:
            return {
                ...state,
                projectList: action.projects
            };
        case SET_SELECTED_TASK:
            return {
                ...state,
                selectedTask: state.projectDetails.tasks.filter(task => task.id === action.taskId)[0]
            };
        case SET_SELECTED_PROJECT:
            return {
                ...state,
                projectDetails: action.projectDetails,
                epics: {
                    ...state.epics,
                    filteredTasks: {
                        ...state.epics.filteredTasks,
                        data: action.projectDetails.tasks,
                        counts: {},
                        totalsPerEpic: [],
                        allEpicsTotal: {}
                    }
                },
                progress: {
                    ...state.progress,
                    filteredTasks: {
                        ...state.progress.filteredTasks,
                        data: action.projectDetails.tasks,
                        counts: {},
                        totalsPerEpic: [],
                        allEpicsTotal: {}
                    }
                }
            };
        case SET_CREATE_PROJECT_INPUT:
            return {
                ...state,
                createProjectInput: action.data
            };

        case FILTER_TASKS: {
            let filteredTasksByStatus = [];
            let filteredTasksByDate = [];

            action.status.map(status => {
                const filter = state.projectDetails.tasks.filter(task => task.status === action.statusList[status]);

                if (filter.length > 0) filteredTasksByStatus.push(...filter);

                return null;
            });

            // filter by modified estimate
            filteredTasksByStatus = filteredTasksByStatus.filter(task => action.modifiedEstimate ? task.totalHoursWorked !== 0 || task.totalEstimateRevisionHours !== 0 || task.totalScopeChanges !== 0 : task);

            if (!action.isInit) {
                // filter by status

                // filter by date (last 7 days and specific date)
                if (action.coveredDate === 0 || action.coveredDate === 2) {
                    filteredTasksByStatus.forEach((task, idx1) => {
                        const logsInsideCoveredDate = [];
                        const logsOutsideCoveredDate = [];

                        task.activityLogs.forEach(log => { // segragate logs that is within and outside the specified date
                            if (moment.utc(log.createdDate).isBetween(moment.utc().subtract(action.numOfDays, 'd'), moment.utc())) {
                                logsInsideCoveredDate.push(log);
                            } else {
                                logsOutsideCoveredDate.push(log);
                            }
                        });

                        let totalWorkLogValue = 0;
                        let totalEstimateRevisionValue = 0;
                        let totalScopeChangeValue = 0;
                        let totalEstimatesAsAt = 0;

                        logsInsideCoveredDate.forEach(log => {
                            const logType = log.type.toLowerCase();

                            if (logType === 'work_log') {
                                totalWorkLogValue += nullChecker(log.logWorkHours);
                            } else if (logType === 'estimate_change_revision') {
                                totalEstimateRevisionValue += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                            } else if (logType === 'estimate_change_scope_change') {
                                totalScopeChangeValue += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                            } else if (logType === 'original_estimate') {
                                totalEstimatesAsAt += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                            }
                        });

                        logsOutsideCoveredDate.forEach(log => {
                            const logType = log.type.toLowerCase();

                            if (logType !== 'activity') {
                                if (logType !== 'work_log') {
                                    totalEstimatesAsAt += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                                } else {
                                    totalEstimatesAsAt -= nullChecker(log.logWorkHours);
                                }
                            }
                        });

                        filteredTasksByDate.push({
                            ...task,
                            originalEstimate: totalEstimatesAsAt,
                            totalHoursWorked: totalWorkLogValue,
                            totalEstimateRevisionHours: totalEstimateRevisionValue,
                            totalScopeChanges: totalScopeChangeValue,
                            currentTotalEstimate: task.originalEstimate + task.totalScopeChanges + task.totalEstimateRevisionHours - task.totalHoursWorked
                        });
                    });
                } else {
                    filteredTasksByDate = filteredTasksByStatus;
                }
            } else {
                filteredTasksByDate = state.projectDetails.tasks;
            }

            // count per epic length
            const totalsPerEpicObj = {};
            const totalsPerEpic = [];
            const tasksCounts = {
                epicLength: [],
                totalTasksDoneLength: []
            };

            action.allLabels.map((epic, idx) => {
                let counter = 0;
                let finishedTaskCounter = 0;
                const overallStatus = [];

                filteredTasksByDate.map(task => {
                    const length = task.labels.filter(label => label.name.toLowerCase() === action.allLabels[idx]).length;

                    if (length > 0) counter++;

                    const tasksDoneLength = task.labels.filter(label => label.name.toLowerCase() === action.allLabels[idx] && task.status.toLowerCase() === 'done').length;

                    if (tasksDoneLength > 0) finishedTaskCounter++;

                    return null;
                });

                tasksCounts.epicLength.push(counter);
                tasksCounts.totalTasksDoneLength.push(finishedTaskCounter);

                // count per epic totals
                totalsPerEpicObj[epic] = {};

                action.keyFields.map(field => {
                    let counter = 0;

                    filteredTasksByStatus.map(task => {
                        const match = task.labels.filter(label => label.name.toLowerCase() === epic).length;

                        if (match > 0) {
                            if (field === 'status') {
                                overallStatus.push(task.status);
                            } else {
                                counter += task[field];
                            }
                        }

                        return null;
                    });

                    if (field === 'status') {
                        totalsPerEpicObj[epic][field] = overallStatus;
                    } else {
                        totalsPerEpicObj[epic][field] = counter;
                    }

                    return null;
                });

                totalsPerEpicObj[epic].currentEstimate = totalsPerEpicObj[epic].originalEstimate + totalsPerEpicObj[epic].totalEstimateRevisionHours + totalsPerEpicObj[epic].totalScopeChanges - (action.estimatedOption === 0 ? totalsPerEpicObj[epic].totalHoursWorked : 0);

                totalsPerEpic.push(totalsPerEpicObj[epic]);

                return null;
            });

            // count epic totals
            const epicTotalsObj = {};

            action.keyFields.map(field => {
                let counter = 0;

                totalsPerEpic.map(total => {
                    counter += total[field];

                    return null;
                });

                epicTotalsObj[field] = counter;

                return null;
            });

            return {
                ...state,
                epics: {
                    ...state.epics,
                    filteredTasks: {
                        ...state.filteredTasks,
                        data: filteredTasksByDate,
                        counts: tasksCounts,
                        totalsPerEpic: totalsPerEpic,
                        allEpicsTotal: epicTotalsObj
                    }
                }
            };
        }

        case FILTER_PROGRESS_TASKS: {
            let filteredTasksByStatus = [];
            let filteredTasksByDate = [];

            if (!action.isInit) {
                // filter by status
                action.progressStatus.map(status => {
                    const filter = state.projectDetails.tasks.filter(task => task.status === action.statusList[status]);

                    if (filter.length > 0) filteredTasksByStatus.push(...filter);

                    return null;
                });

                // filter by modified estimate
                filteredTasksByStatus = filteredTasksByStatus.filter(task => action.progressedTask ? task.totalHoursWorked !== 0 : task);

                // filter by date (last 7 days and specific date)
                if (action.coveredDate === 0 || action.coveredDate === 2) {
                    filteredTasksByStatus.forEach((task, idx1) => {
                        const logsInsideCoveredDate = [];
                        const logsOutsideCoveredDate = [];

                        task.activityLogs.forEach(log => { // segragate logs that is within and outside the specified date
                            if (moment.utc(log.createdDate).isBetween(moment.utc().subtract(action.numOfDays, 'd'), moment.utc())) {
                                logsInsideCoveredDate.push(log);
                            } else {
                                logsOutsideCoveredDate.push(log);
                            }
                        });

                        let totalWorkLogValue = 0;
                        let totalEstimateRevisionValue = 0;
                        let totalScopeChangeValue = 0;
                        let totalEstimatesAsAt = 0;

                        logsInsideCoveredDate.forEach(log => {
                            const logType = log.type.toLowerCase();

                            if (logType === 'work_log') {
                                totalWorkLogValue += nullChecker(log.logWorkHours);
                            } else if (logType === 'estimate_change_revision') {
                                totalEstimateRevisionValue += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                            } else if (logType === 'estimate_change_scope_change') {
                                totalScopeChangeValue += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                            } else if (logType === 'original_estimate') {
                                totalEstimatesAsAt += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                            }
                        });

                        logsOutsideCoveredDate.forEach(log => {
                            const logType = log.type.toLowerCase();

                            if (logType !== 'activity') {
                                if (logType !== 'work_log') {
                                    totalEstimatesAsAt += nullChecker(log.estimateChangeTo) - nullChecker(log.estimateChangeFrom);
                                } else {
                                    totalEstimatesAsAt -= nullChecker(log.logWorkHours);
                                }
                            }
                        });

                        filteredTasksByDate.push({
                            ...task,
                            originalEstimate: totalEstimatesAsAt,
                            totalHoursWorked: totalWorkLogValue,
                            totalEstimateRevisionHours: totalEstimateRevisionValue,
                            totalScopeChanges: totalScopeChangeValue,
                            currentTotalEstimate: task.originalEstimate + task.totalScopeChanges + task.totalEstimateRevisionHours
                        });
                    });
                } else {
                    filteredTasksByDate = filteredTasksByStatus;
                }
            } else {
                filteredTasksByDate = state.projectDetails.tasks;
                filteredTasksByStatus = state.projectDetails.tasks;
            }

            // count per epic length
            const totalsPerEpicObj = {};
            const totalsPerEpic = [];
            const tasksCounts = {
                epicLength: [],
                totalTasksDoneLength: []
            };

            action.allLabels.map((epic, idx) => {
                let counter = 0;
                let finishedTaskCounter = 0;
                const overallStatus = [];

                filteredTasksByDate.map(task => {
                    const length = task.labels.filter(label => label.name.toLowerCase() === action.allLabels[idx]).length;

                    if (length > 0) counter++;

                    const tasksDoneLength = task.labels.filter(label => label.name.toLowerCase() === action.allLabels[idx] && task.status.toLowerCase() === 'done').length;

                    if (tasksDoneLength > 0) finishedTaskCounter++;

                    return null;
                });

                tasksCounts.epicLength.push(counter);
                tasksCounts.totalTasksDoneLength.push(finishedTaskCounter);

                // count per epic totals
                totalsPerEpicObj[epic] = {};

                action.keyFields.map(field => {
                    let counter = 0;

                    filteredTasksByDate.map(task => {
                        const match = task.labels.filter(label => label.name.toLowerCase() === epic).length;

                        if (match > 0) {
                            if (field === 'status') {
                                overallStatus.push(task.status);
                            } else if (field === 'progress') {
                                counter += task.totalHoursWorked;
                            } else if (field === 'remainingEstimate') {
                                counter += task.originalEstimate + task.totalEstimateRevisionHours + task.totalScopeChanges - task.totalHoursWorked;
                            }
                        }

                        return null;
                    });

                    filteredTasksByStatus.map(task => {
                        const match = task.labels.filter(label => label.name.toLowerCase() === epic).length;

                        if (match > 0) {
                            if (field === 'currentTotalEstimate') {
                                counter += task.originalEstimate + task.totalEstimateRevisionHours + task.totalScopeChanges;
                            }
                        }

                        return null;
                    });

                    if (field === 'status') {
                        totalsPerEpicObj[epic][field] = overallStatus;
                    } else {
                        totalsPerEpicObj[epic][field] = counter;
                    }

                    return null;
                });

                totalsPerEpic.push(totalsPerEpicObj[epic]);

                return null;
            });

            // count epic totals
            const epicTotalsObj = {};

            action.keyFields.map(field => {
                let counter = 0;

                totalsPerEpic.map(total => {
                    counter += total[field];

                    return null;
                });

                epicTotalsObj[field] = counter;

                return null;
            });

            return {
                ...state,
                progress: {
                    ...state.progress,
                    filteredTasks: {
                        ...state.filteredTasks,
                        data: filteredTasksByDate,
                        counts: tasksCounts,
                        totalsPerEpic: totalsPerEpic,
                        allEpicsTotal: epicTotalsObj
                    }
                }
            };
        }

        case ADD_TASK:
            return {
                ...state,
                selectedTask: {
                    ...state.selectedTask,
                    originalEstimate: action.payload.originalEstimate,
                    activityLogs: [action.payload.newLog, ...state.selectedTask.activityLogs]
                }
            };
        case UPDATE_TASK:
            return {
                ...state,
                selectedTask: {
                    ...state.selectedTask,
                    currentEstimate: action.payload.newEstimate,
                    activityLogs: [action.payload.newLog, ...state.selectedTask.activityLogs]
                }
            };
        case ADD_TASK_FROM_TABLE:
            return {
                ...state,
                epics: {
                    ...state.epics,
                    filteredTasks: {
                        ...state.epics.filteredTasks,
                        data: state.epics.filteredTasks.data.map(task => task.id === action.payload.taskId
                            ? {
                                ...task,
                                originalEstimate: action.payload.originalEstimate
                            }
                            : task)
                    }
                }
            };
        case UPDATE_TASK_FROM_TABLE:
            return {
                ...state,
                epics: {
                    ...state.epics,
                    filteredTasks: {
                        ...state.epics.filteredTasks,
                        data: state.epics.filteredTasks.data.map(task => task.id === action.payload.taskId
                            ? {
                                ...task,
                                currentEstimate: action.payload.newEstimate
                            }
                            : task)
                    }
                }
            };
        case OFFSET_FILTERED_TASKS_COUNT:
            return {
                ...state,
                [action.tableType]: {
                    ...state[action.tableType],
                    filteredTasks: {
                        ...state[action.tableType].filteredTasks,
                        counts: {
                            epicLength: action.epicLength,
                            totalTasksDoneLength: action.totalTasksDoneLength
                        }
                    }
                }
            };
        case OFFSET_FILTERED_TASKS_TOTALS_PER_EPIC_COUNT:
            return {
                ...state,
                [action.tableType]: {
                    ...state[action.tableType],
                    filteredTasks: {
                        ...state[action.tableType].filteredTasks,
                        totalsPerEpic: action.epicTotals
                    }
                }
            };
        case RESET_PROJECT_STATE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

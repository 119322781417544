// @flow
import moment from 'moment';

/**
 * Capitalize a string
 * @param {String} str
 * @returns {String}
 */
export function capitalize(str: string): string {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export const getTextColor = (hrs, hasDanger = true, isSuccess = false) => {
    if (isSuccess) {
        return hrs < 0
            ? 'text-success'
            : hasDanger && hrs <= 0.9 && hrs >= 0
                ? 'text-danger'
                : '';
    }

    return hrs && hrs < 0
        ? 'text-success'
        : formatHours(hrs) !== '—' && formatHours(hrs) !== '0 Hrs.'
            ? hasDanger
                ? 'text-danger'
                : ''
            : '';
};

export const formatHours = (hrs = 0, isIncludeZero = false) => {
    try {
        const result = hrs !== undefined && hrs !== null ? `${hrs.toFixed(1) % 2 > 1 ? `${hrs.toFixed(1)} Hrs.` : !isIncludeZero ? `${hrs.toFixed(0)} Hrs.` : hrs.toFixed(0) === 0 ? '—' : `${hrs.toFixed(0)} Hrs.`}` : '—';

        return result === '0 Hrs.' ? '—' : result;
    } catch (error) {
        return '—';
    }
};

export const formatDate = (date: String) => {
    try {
        return moment(date).format('D MMMM YYYY, hh:mm a');
    } catch (error) {
        return date;
    }
};

export const nullChecker = (value: Integer) => {
    return value === null ? 0 : value;
};

export const randomNumber = () => {
    return Math.random().toString(36).substr(2, 5);
};

export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const isValidEmail = (str: string): boolean => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);

export const mergeDuplicateValuesFromArrayOfObject = (obj: Object, key: String) => {
    const seen = [];

    obj.filter((entry) => {
        const exist = seen.filter(s => s.label === entry[key]);

        if (exist.length > 0) {
            exist[0].count++;
        } else {
            seen.push({
                label: entry[key],
                count: 1,
                projectId: entry.projectId
            });
        }
    });

    return seen;
};

export const offsetIndex = (from, to, arr, setter, countTotalArr, tasksEpicLength, tasksTotalTasksDone, totalsPerEpicArr, tasksTotalsPerEpicCount) => {
    if (to > -1) {
        if (from < to) {
            const start = arr.slice(0, from);
            const between = arr.slice(from + 1, to + 1);
            const end = arr.slice(to + 1);

            const epicLengthCountStart = countTotalArr.epicLength.slice(0, from);
            const epicLengthCountBetween = countTotalArr.epicLength.slice(from + 1, to + 1);
            const epicLengthCountEnd = countTotalArr.epicLength.slice(to + 1);

            const tasksTotalLengthCountStart = countTotalArr.totalTasksDoneLength.slice(0, from);
            const tasksTotalLengthCountBetween = countTotalArr.totalTasksDoneLength.slice(from + 1, to + 1);
            const tasksTotalLengthCountEnd = countTotalArr.totalTasksDoneLength.slice(to + 1);

            const tasksTotalsPerEpicCountStart = totalsPerEpicArr.slice(0, from);
            const tasksTotalsPerEpicCountBetween = totalsPerEpicArr.slice(from + 1, to + 1);
            const tasksTotalsPerEpicCountEnd = totalsPerEpicArr.slice(to + 1);

            setter([...start, ...between, arr[from], ...end]);
            tasksEpicLength([...epicLengthCountStart, ...epicLengthCountBetween, countTotalArr.epicLength[from], ...epicLengthCountEnd]);
            tasksTotalTasksDone([...tasksTotalLengthCountStart, ...tasksTotalLengthCountBetween, countTotalArr.totalTasksDoneLength[from], ...tasksTotalLengthCountEnd]);
            tasksTotalsPerEpicCount([...tasksTotalsPerEpicCountStart, ...tasksTotalsPerEpicCountBetween, totalsPerEpicArr[from], ...tasksTotalsPerEpicCountEnd]);
        }

        if (from > to) {
            const start = arr.slice(0, to);
            const between = arr.slice(to, from);
            const end = arr.slice(from + 1);

            const epicLengthCountStart = countTotalArr.epicLength.slice(0, to);
            const epicLengthCountBetween = countTotalArr.epicLength.slice(to, from);
            const epicLengthCountEnd = countTotalArr.epicLength.slice(from + 1);

            const tasksTotalLengthCountStart = countTotalArr.totalTasksDoneLength.slice(0, to);
            const tasksTotalLengthCountBetween = countTotalArr.totalTasksDoneLength.slice(to, from);
            const tasksTotalLengthCountEnd = countTotalArr.totalTasksDoneLength.slice(from + 1);

            const tasksTotalsPerEpicCountStart = totalsPerEpicArr.slice(0, to);
            const tasksTotalsPerEpicCountBetween = totalsPerEpicArr.slice(to, from);
            const tasksTotalsPerEpicCountEnd = totalsPerEpicArr.slice(from + 1);

            setter([...start, arr[from], ...between, ...end]);
            tasksEpicLength([...epicLengthCountStart, countTotalArr.epicLength[from], ...epicLengthCountBetween, ...epicLengthCountEnd]);
            tasksTotalTasksDone([...tasksTotalLengthCountStart, countTotalArr.totalTasksDoneLength[from], ...tasksTotalLengthCountBetween, ...tasksTotalLengthCountEnd]);
            tasksTotalsPerEpicCount([...tasksTotalsPerEpicCountStart, totalsPerEpicArr[from], ...tasksTotalsPerEpicCountBetween, ...tasksTotalsPerEpicCountEnd]);
        }
    }
};

export const defaultDrageState = {
    row: -1,
    startPoint: null,
    dropIndex: -1
};

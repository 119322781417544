import React from 'react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import WrapperBackground from '../../components/base/WrapperBackground/WrapperBackground';

const loading = () => (
    <WrapperBackground>
        <Spinner animation='border' role='status' color='secondary'>
            <span className='sr-only'>Loading...</span>
        </Spinner>
    </WrapperBackground>
);

// Container
const DefaultLayout = React.lazy(() => import('./../Layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./../Login/LoginContainer'));
const ForgotPassword = React.lazy(() => import('./../ForgotPassword/ForgotPasswordContainer'));
const ResetPassword = React.lazy(() => import('./../ResetPassword/ResetPasswordContainer'));

function App() {
    return (
        <BrowserRouter>
            <React.Suspense fallback={loading()}>
                <Switch>
                    <Route exact={true} path='/login' name='Login Page'
                        render={props => <Login {...props}/>} />
                    <Route path='/forgot-password' name='Forgot Password' render={props => <ForgotPassword {...props}/>} />
                    <Route path='/reset-password' name='Reset Password' render={props => <ResetPassword {...props}/>} />
                    <Route path='/' name='Home' render={props => <DefaultLayout {...props}/>} />
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    );
}

export default withRouter(App);
